import { isEmpty, isPresent } from '@ember/utils';

import ENV from 'tablet-frontend/config/environment';

export default function cookie(parent, transition) {
  const hashFromParams = transition.to.queryParams.hash;

  // hash from query param has priority, it also sets cookie
  // if hash fails we check in cookie
  if (isPresent(hashFromParams)) {
    parent.set('surveyHash', hashFromParams);
    parent.cookieService.write(
      ENV.cookies.survey,
      hashFromParams,
      ENV.cookies.defaultOptions
    );
  } else {
    const surveyHashFromCookie = parent.cookieService.read(ENV.cookies.survey);
    parent.set('surveyHash', surveyHashFromCookie);
  }

  const deviceNameFromCookie = parent.cookieService.read(
    ENV.cookies.deviceName
  );
  parent.set('device.name', deviceNameFromCookie);

  // when hash is missing both in params and cookie we show 404 page
  if (isEmpty(parent.surveyHash)) {
    return parent.router.transitionTo('not-found');
  }

  // when no device name we open setup page
  if (isEmpty(parent.device.name)) {
    return parent.router.transitionTo('survey.setup');
  } else {
    return parent.router.transitionTo('survey.score');
  }
}
