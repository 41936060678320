import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { isPresent } from '@ember/utils';

export default class AppTopLine extends Component {
  get componentStyle() {
    return isPresent(this.args.color)
      ? htmlSafe(`background-color: ${this.args.color};`)
      : undefined;
  }
}
