import Service, { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';

export default class SessionService extends Service {
  @service() intl;

  async loadTranslation(locale) {
    const formattedLocale = dasherize(locale);
    const translations = await fetch(`/translations/${formattedLocale}.json`);
    this.intl.addTranslations(formattedLocale, await translations.json());
  }
}
