import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { v1 } from 'ember-uuid';
import { inject as service } from '@ember/service';

import { sendScoreWithComment } from 'tablet-frontend/utils/survey';

export default class ScoreController extends Controller {
  @service('reset-modal') resetModalService;
  @service router;
  @service device;
  @service store;

  survey = null;
  answer = null;
  isTest = false;
  selectedScore = null;

  queryParams = {
    answerId: 'answer_id',
  };

  @(task(function* (score) {
    this.store.pushPayload({
      answer: {
        id: this.answer.id,
        score,
      },
    });
    yield this.router.transitionTo('survey.answer', this.answer.id);
  }).drop())
  voteTask;

  @(task(function* () {
    this.resetModalService.set('modalVisible', false);
    yield sendScoreWithComment(this, this.survey, this.answer, this.isTest);
    this.answerId = undefined;
    this.selectedScore = null;
    this.answer = this.store.createRecord('answer', {
      id: v1(), // we generate temporary id for offline mode
    });
  }).drop())
  onResetTask;
}
