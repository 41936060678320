import fetch from 'fetch';
import { isPresent } from '@ember/utils';

import ENV from 'tablet-frontend/config/environment';

export async function sendScoreWithComment(parent, survey, answer, isTest) {
  // send score
  const scoreData = new FormData();
  scoreData.append('properties[device_name]', parent.device.name);
  scoreData.append('format', 'json');
  scoreData.append('is_a_test', isTest);

  const response = await fetch(
    `${ENV.apiHost}/web/response/${survey.publicHashId}/tablet_channel/${answer.score}`,
    {
      body: scoreData,
      method: 'post',
    }
  );
  const answerPayload = await response.json();

  // send comment
  const answerData = new FormData();

  if (isPresent(answer.response)) {
    answerData.append('response', answer.response);
  }

  answerData.append('format', 'json');
  answerData.append('is_a_test', isTest);

  await fetch(
    `${ENV.apiHost}/web/response/${answerPayload.answer.id}/add_comment`,
    {
      body: answerData,
      method: 'put',
    }
  );

  // update store
  parent.store.pushPayload({
    answer: {
      id: answer.id,
      answer_id: answerPayload.answer.id,
      response: answer.response,
    },
  });
}
