import Route from '@ember/routing/route';
import { v1 } from 'ember-uuid';
import { isPresent } from '@ember/utils';
import fetch from 'fetch';
import { inject as service } from '@ember/service';

import ENV from 'tablet-frontend/config/environment';

export default class ScoreRoute extends Route {
  @service('reset-modal') resetModalService;
  @service store;

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    let answer;
    const answerId = transition.to.queryParams.answer_id;

    if (isPresent(answerId)) {
      answer = this.store.peekRecord('answer', answerId);
    }

    // we check in case where there is query param but no record in store
    if (isPresent(answer)) {
      this.resetModalService.startTask.perform(this.resetModalService);
      controller.set('selectedScore', answer.get('score'));
    } else {
      answer = this.store.createRecord('answer', {
        id: v1(), // we generate temporary id for offline mode
      });
      controller.set('selectedScore', null);
    }

    controller.setProperties({
      survey: this.modelFor('survey').survey,
      isTest: this.modelFor('survey').isTest,
      answer,
    });
  }

  activate() {
    fetch(
      `${ENV.apiHost}/web/surveys/${
        this.modelFor('survey').survey.publicHashId
      }/viewed`,
      {
        method: 'post',
      }
    );
  }
}
