import Route from '@ember/routing/route';
import { isNone } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class AnswerRoute extends Route {
  @service('reset-modal') resetModalService;
  @service router;
  @service store;

  model(params) {
    const answer = this.store.peekRecord('answer', params.answer_id);

    if (isNone(answer)) {
      this.router
        .transitionTo('survey.score')
        .then(() => this.resetModalService.stop());
    }

    return answer;
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.setProperties({
      survey: this.modelFor('survey').survey,
      isTest: this.modelFor('survey').isTest,
      answer: model,
      collapsedLogo: false,
    });
  }

  activate() {
    this.resetModalService.resetInterval = this.modelFor(
      'survey'
    ).survey.resetInterval;
    this.resetModalService.startTask.perform(this.resetModalService);
  }

  deactivate() {
    this.resetModalService.stop();
  }
}
