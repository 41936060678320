import { action } from '@ember/object';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

import { sendScoreWithComment } from 'tablet-frontend/utils/survey';

export default class AnswerController extends Controller {
  @service('reset-modal') resetModalService;
  @service router;
  @service device;
  @service store;

  answer = null;
  survey = null;
  isTest = false;
  hasLogo = true;
  collapsedLogo = false;

  @(task(function* () {
    this.store.pushPayload({
      answer: {
        id: this.answer.id,
        response: this.answer.response,
      },
    });

    yield this.router.transitionTo('survey.thank-you', this.answer.id);
  }).drop())
  commentTask;

  @(task(function* () {
    this.resetModalService.set('modalVisible', false);
    this.router.transitionTo('survey.score');
    yield sendScoreWithComment(this, this.survey, this.answer, this.isTest);
  }).drop())
  onResetTask;

  @action
  onResponseChange(value) {
    // update value as component doesn't do it automatically when using 'onChange' event
    this.answer.set('response', value);

    // we reset inactivity timeout
    this.resetModalService.startTask.perform(this.resetModalService);
  }

  @action
  goBack() {
    this.router.transitionTo('survey.score', {
      queryParams: {
        answer_id: this.answer.id,
      },
    });
  }
}
