import EmberRouter from '@ember/routing/router';
import config from 'tablet-frontend/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('survey', function () {
    this.route('score');
    this.route('setup');
    this.route('answer', { path: '/answer/:answer_id' });
    this.route('thank-you', { path: '/thank-you/:answer_id' });
  });

  this.route('not-found');

  // This must be last route definition!
  this.route('unknown', { path: '/*unknown' });
});
