import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

import ENV from 'tablet-frontend/config/environment';

export default class SetupRoute extends Route {
  @service('cookies') cookieService;
  @service device;

  acceptCookies = null;

  beforeModel() {
    super.beforeModel(...arguments);

    const acceptCookies =
      this.cookieService.read(ENV.cookies.acceptCookies) === 'true'; // value from cookie is string
    this.acceptCookies = acceptCookies;
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.setProperties({
      deviceName: this.device.name,
      acceptCookies: this.acceptCookies,
      survey: this.modelFor('survey').survey,
    });
  }
}
