import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import ENV from 'tablet-frontend/config/environment';

export default class ResetTimer extends Component {
  @tracked counter = 0;
  @tracked instance = null;

  get time() {
    return ENV.APP.modalTimeout / 1000 - 1; // whole timeout takes one second more then real time for better UX
  }

  @action
  didInsert() {
    const time = this.time;
    const initialOffset = '500';
    let i = 0;

    this.counter = time;
    this.instance = setInterval(() => {
      if (i === time) {
        clearInterval(this.instance);
        this.counter--;
        return;
      }

      i++;

      if (i > 1) {
        this.counter--;
      }

      let mainArc = document.querySelector('.main-arc');
      mainArc.style.strokeDashoffset = i * (initialOffset / time);
    }, 1000);
  }

  willDestroy() {
    super.willDestroy(...arguments);

    clearInterval(this.instance);
  }
}
