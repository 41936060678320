import Transform from '@ember-data/serializer/transform';

export default class TestTransform extends Transform {
  deserialize(serialized) {
    if (typeof serialized === 'object' && serialized !== null) {
      return serialized;
    } else {
      return {};
    }
  }

  serialize(deserialized) {
    if (typeof deserialized === 'object' && deserialized !== null) {
      return deserialized;
    } else {
      return {};
    }
  }
}
