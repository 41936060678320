import Model, { attr } from '@ember-data/model';

export default class Survey extends Model {
  @attr('string') actionButtonColor;
  @attr('boolean') commentByScoreEnabled;
  @attr('string') commentDetractorMessage;
  @attr('string') commentPageMessage;
  @attr('string') commentPagePlaceholder;
  @attr('string') commentPageSubmit;
  @attr('string') commentPassiveMessage;
  @attr('string') commentPromoterMessage;
  @attr('string') footer;
  @attr('boolean') isWhiteLabel;
  @attr('string') languageLocale;
  @attr('object') logoUrls;
  @attr('string') publicHashId;
  @attr('string') question;
  @attr('string') questionSubject;
  @attr('number') resetInterval;
  @attr('boolean') hasLabels;
  @attr('string') labelScoreMax;
  @attr('string') labelScoreMin;
  @attr('string') textColor;
  @attr('boolean') thankYouByScoreEnabled;
  @attr('string') thankYouDetractorMessage;
  @attr('string') thankYouPageMessage;
  @attr('string') thankYouPassiveMessage;
  @attr('string') thankYouPromoterMessage;
  @attr('string') topBannerColor;
  @attr('string') version;
}
