import Route from '@ember/routing/route';
import { task, timeout } from 'ember-concurrency';
import { isNone } from '@ember/utils';
import { inject as service } from '@ember/service';

import { sendScoreWithComment } from 'tablet-frontend/utils/survey';

export default class ThankYouRoute extends Route {
  @service('reset-modal') resetModalService;
  @service router;
  @service device;
  @service store;

  isTest = false;

  model(params) {
    const answer = this.store.peekRecord('answer', params.answer_id);

    if (isNone(answer)) {
      this.router.transitionTo('survey.score');
    }

    return answer;
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.setProperties({
      survey: this.modelFor('survey').survey,
      answer: model,
    });

    this.finalizeSurveyTask.perform(
      this.modelFor('survey').survey,
      model,
      this.modelFor('survey').isTest
    );
  }

  @task(function* (survey, answer, isTest) {
    yield timeout(this.resetModalService.thankYouTimeout);
    yield sendScoreWithComment(this, survey, answer, isTest);
    yield this.router.transitionTo('survey.score', {
      queryParams: {
        answer_id: undefined,
      },
    });
  })
  finalizeSurveyTask;
}
