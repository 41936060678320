import { htmlSafe } from '@ember/template';
import { isPresent, isNone } from '@ember/utils';
import Component from '@glimmer/component';

export default class StyledButton extends Component {
  get componentStyle() {
    if (isNone(this.args.survey)) {
      return undefined;
    }

    let style = '';
    const { actionButtonColor, textColor } = this.args.survey;

    if (actionButtonColor) {
      style += `background-color:${actionButtonColor};border-color:${actionButtonColor};`;
    }

    if (textColor) {
      style += `color:${textColor};`;
    }

    return isPresent(style) ? htmlSafe(style) : undefined;
  }
}
