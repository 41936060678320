import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';

export default class AnswerMessage extends Component {
  replaceWithElement(inputString, targetString, replacementElement) {
    let result = [];
    const parts = inputString.split(targetString);

    if (parts.length === 1) {
      result = parts;
    } else {
      parts.forEach((part, idx) => {
        result.push(part);

        if (idx < parts.length - 1) {
          result.push(replacementElement);
        }
      });
    }
    return result;
  }

  get text() {
    let message = this.args.message;

    if (this.args.enableByScore) {
      if (this.args.score >= 9) {
        message = this.args.promoterMessage;
      } else if (this.args.score <= 6) {
        message = this.args.detractorMessage;
      } else {
        message = this.args.passiveMessage;
      }
    }

    return message;
  }

  get scoreSelectedHtml() {
    return `<span class="score">${this.args.score}</span>`;
  }

  get textTransformed() {
    return isPresent(this.text)
      ? this.replaceWithElement(
          this.text.toString(),
          `%[score]`,
          this.scoreSelectedHtml
        )
      : [];
  }
}
