import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class UnknownRoute extends Route {
  @service router;

  redirect() {
    this.router.transitionTo('index'); // return to home route for any invalid paths
  }
}
