import { helper } from '@ember/component/helper';
import podNames from 'ember-component-css/pod-names';
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';

export default helper(function podStyles() {
  assert('pod path must be present', isPresent(arguments[0]));

  let podClasses = '';
  podClasses = arguments[0].map((path) => podNames[path]).join(' ');

  return podClasses;
});
