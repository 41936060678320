import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

import cookieUtil from 'tablet-frontend/utils/cookie';

export default class IndexRoute extends Route {
  @service('cookies') cookieService;
  @service device;
  @service router;

  surveyHash = null;

  beforeModel(transition) {
    super.beforeModel(...arguments);

    cookieUtil(this, transition);
  }

  redirect() {
    this.router.transitionTo('survey.setup');
  }
}
