import Service from '@ember/service';
import { task, timeout } from 'ember-concurrency';

import ENV from 'tablet-frontend/config/environment';

export default class ResetModalService extends Service {
  resetInterval = null;
  modalVisible = false;
  modalTimeout = ENV.APP.modalTimeout;
  thankYouTimeout = ENV.APP.thankYouTimeout;

  @(task(function* (service) {
    if (service.resetInterval === 0) {
      return;
    }

    yield timeout(service.resetInterval * 1000); // since in ms
    service.set('modalVisible', true);
  }).restartable())
  startTask;

  stop() {
    this.startTask.cancelAll();
    this.modalVisible = false;
  }
}
