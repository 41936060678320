import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';

export default class SurveyQuestion extends Component {
  get surveyQuestion() {
    return isPresent(this.args.question)
      ? this.args.question.replace('%[subject]', this.args.questionSubject)
      : undefined;
  }
}
