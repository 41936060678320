import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

import ENV from 'tablet-frontend/config/environment';


export default class SetupController extends Controller {
  @service('cookies') cookieService;
  @service router;
  @service device;

  @tracked displayDeviceNameError = false;
  @tracked displayCookiesNotCheckedError = false;
  @tracked isValidForm = true;
  deviceName = null;
  acceptCookies = null;
  survey = null;

  @action
  start() {
    this._validateForm();
    if (this.isValidForm) {
      this.cookieService.write(
        ENV.cookies.deviceName,
        this.deviceName,
        ENV.cookies.defaultOptions
      );
      this.cookieService.write(
        ENV.cookies.acceptCookies,
        this.acceptCookies,
        ENV.cookies.defaultOptions
      );
      this.device.name = this.deviceName;
      this.router.transitionTo('survey.score');
      parent.postMessage('fullscreenMode', '*');
    }
  }

  _validateForm() {
    this.displayDeviceNameError = isEmpty(this.deviceName);
    this.displayCookiesNotCheckedError = !this.acceptCookies;
    this.isValidForm = !isEmpty(this.deviceName) && this.acceptCookies;
  }
}
