import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import ENV from 'tablet-frontend/config/environment';

export default class ApplicationRoute extends Route {
  @service('session') sessionService;
  @service intl;

  async beforeModel() {
    super.beforeModel(...arguments);

    await this.sessionService.loadTranslation(ENV.APP.defaultLanguageLocale);
    this.intl.setLocale([ENV.APP.defaultLanguageLocale]);
  }
}
