import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class ResetModal extends Component {
  @service('reset-modal') resetModalService;
  @service router;
  @service store;

  @tracked timerCompleted = false;

  @task(function* () {
    yield timeout(this.args.timeout);

    if (typeof this.args.onTimerEnd === 'function') {
      this.args.onTimerEnd();
    }

    this.timerCompleted = true;
  })
  timerTask;

  @action
  cancelTimer() {
    this.timerTask.cancelAll();
  }
}
