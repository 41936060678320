import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import { FaNameMapping } from './icon-names';

export default class AppSvgIconComponent extends Component {
  @tracked svgName;

  constructor() {
    super(...arguments);

    this.updateSvgName(this.args.name);
  }

  getSvgName(name) {
    if (isPresent(FaNameMapping[name])) {
      return FaNameMapping[name];
    } else {
      return name;
    }
  }

  get faName() {
    let name = this.svgName.split('-');
    const possibleTypes = ['solid', 'regular', 'light', 'duotone'];
    const type = name.pop();

    return possibleTypes.includes(type) ? name.join('-') : this.svgName;
  }

  get type() {
    let name = this.svgName.split('-');

    return name.pop();
  }

  get prefix() {
    switch (this.type) {
      case 'solid':
        return 'fas';
      case 'regular':
        return 'far';
      case 'duotone':
        return 'fad';
      default:
        return 'fal';
    }
  }

  get componentStyle() {
    let style = '';

    if (this.args.fontSize > 0) {
      style = `font-size: ${this.args.fontSize}px;`;
    }

    return htmlSafe(style);
  }

  @action
  updateSvgName(name) {
    this.svgName = this.getSvgName(name);
  }
}
