import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';

export default class AppLogo extends Component {
  get hasLogo() {
    return isPresent(this.args.url);
  }

  get logoUrl() {
    return this.hasLogo ? this.args.url : undefined;
  }
}
