import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { run } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import fetch from 'fetch';
import { isNone } from '@ember/utils';

import ENV from 'tablet-frontend/config/environment';
import cookieUtil from 'tablet-frontend/utils/cookie';

export default class SurveyRoute extends Route {
  @service('poll') pollService;
  @service('cookies') cookieService;
  @service('session') sessionService;
  @service device;
  @service raven;
  @service intl;
  @service router;
  @service store;

  pollId = null;
  surveyHash = null;

  async getConfiguration() {
    try {
      let response = await fetch(
        `${ENV.configHost}/survey-settings/${this.surveyHash}`
      );
      response = await response.json();
      const surveyPayload = {
        survey: response.overlay_settings, // remap `overlay_settings` into `survey`
      };

      if (isNone(surveyPayload.survey)) {
        return;
      }

      if (surveyPayload.survey.version !== ENV['app-version']) {
        // if reload happens more then once we should create exception
        if (this.cookieService.exists(ENV.cookies.reload)) {
          this.raven.captureException(
            'To many version redirects. Check if current version matches overlay backend env'
          );
        } else {
          this.cookieService.write(
            ENV.cookies.reload,
            'true',
            ENV.cookies.defaultOptions
          );
        }

        // delay reload so we don't jump into reload loop
        run.later(() => {
          location.reload();
        }, ENV.APP.reloadTimeout);
      } else {
        this.cookieService.clear(ENV.cookies.reload);
      }

      this.store.pushPayload(surveyPayload); // push survey data to store
      const survey = this.store.peekRecord('survey', surveyPayload.survey.id); // peek survey record so it can be used after resolve
      await this.sessionService.loadTranslation(survey.get('languageLocale'));
      this.intl.setLocale([
        survey.get('languageLocale'),
        ENV.APP.defaultLanguageLocale,
      ]);
      return survey;
    } catch (error) {
      throw new Error(error);
    }
  }

  model(params, transition) {
    return hash({
      survey: this.getConfiguration(),
      isTest: transition.to.queryParams.is_a_test === 'true',
    });
  }

  redirect(model) {
    if (isEmpty(model.survey)) {
      this.router.transitionTo('not-found');
    }
  }

  beforeModel(transition) {
    super.beforeModel(...arguments);

    cookieUtil(this, transition);
  }

  afterModel() {
    super.afterModel(...arguments);

    // Make sure we only create one poll instance
    if (!this.pollId) {
      this.pollId = this.pollService.addPoll({
        interval: ENV.APP.pollInterval,
        callback: this.getConfiguration.bind(this),
      });
    }
  }

  deactivate() {
    this.pollService.stopPoll(this.pollId);
  }
}
